/* You can add global styles to this file, and also import other style files */
@import '../../../node_modules/@ionic/angular/css/ionic.bundle.css';

body {
  margin: 0;
}

.success-snackbar {
  background: rgb(0, 134, 18);
  color: #fff;
}

.error-snackbar {
  background: rgb(153, 0, 0);
  color: #fff;
}

li {
  outline: none;
}

div {
  outline: none;
}
